import React from 'react';
import Layout from '../../../../components/Layout';
import SEO from '../../../../components/seo';
import Block from '../../../../components/Block';
import {graphql, useStaticQuery} from 'gatsby';
import Hero from '../../../../components/Hero';
import {
	HighlightedParagraph,
	HighlightedTitle,
} from '../../../../components/highlightedText';
import {getTranslated, lang} from '../../../../helpers/lang';
import Breadcrumb from '../../../../components/Breadcrumb';
import Subnavigation from '../../../../components/Subnavigation';
import Anchors from '../../../../components/Anchors';
import PersonBadge from '../../../../components/PersonBadge';
import Content from '../../../../components/Content';

const ProjectManager23 = ({pageContext}) => {
	const position = pageContext.position;
	const data = useStaticQuery(graphql`
		{
			background: file(
				relativePath: {eq: "pages/company/openPositions/gisOperator.jpg"}
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
		}
	`);

	return (
		<Layout>
			<SEO title="Project Manager" />
			<Hero
				backgroundImageFluid={data.background.childImageSharp.gatsbyImageData}
			>
				<Breadcrumb crumbs={pageContext?.crumbs} />
				<HighlightedTitle>
					{getTranslated(position.title, lang)}
				</HighlightedTitle>
				<HighlightedParagraph>
					{getTranslated(position.description, lang)}
				</HighlightedParagraph>
			</Hero>
			<Block light>
				<Content>
					<Subnavigation>
						<Anchors>
							<a href={'#your-job'}>Your job</a>
							<a href={'#our-job'}>Our job</a>
							<a href={'#our-way-of-work'}>Our way of work</a>
							<a href={'#what-you-get'}>What do you get for it?</a>
							<a href={'#hiring-process'}>Hiring process</a>
						</Anchors>

						{position.open ? (
							<PersonBadge
								note={pageContext.position.contactNote}
								cta={pageContext.position.contactCta}
								email={pageContext.position.contactPerson}
							/>
						) : (
							<div className={'gst-responsible-person'}>
								<div className={'gst-position-closed'}>
									This position is no longer open.
								</div>
							</div>
						)}
					</Subnavigation>
				</Content>
			</Block>
			<Block light>
				<Content>
					<p>
						We are looking for a project manager with hands-on experience in
						setting up working processes in an agile environment. If you would
						like to work on a variety of projects with a big impact for a small
						but well-established company, get in touch!
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h3 id={'your-job'}>Your job</h3>
					<p>
						Ideally, you have helped others before. You have experienced an
						agile transformation and have helped some teams go through that
						transformation. You understand how important communication,
						processes and relationships are across teams. You realize that
						mutual understanding and aiming for shared goals is essential within
						the company but also with the stakeholders.
					</p>
					<p>
						You have a skill set to plan, implement and review different
						projects. You don't have to understand remote sensing data or
						programming as a specialist. We will want you to pass on the best
						practices and skills in agile methods inside the company and on the
						other hand, we will provide you with some space for your
						self-improvement - even in a different direction, you´re interested
						in. For that, we have dedicated time. As people, we know the
						importance of people.
					</p>
					<p>
						You are not afraid to bring new ideas and can apply it in project
						management. You will closely cooperate with our product owners and
						development team, so you will get the chance to connect with people
						and help them bring solutions. You can facilitate discussions across
						teams and help them develop skills in agile methods. Since we are
						results driven in Gisat, certification in Scrum or Agile is not
						necessary but definitely may give you some extra points!
					</p>
					<p>
						The vast majority of materials are in English, as most clients are
						international, and so is our internal documentation. Also, some of
						our colleagues are foreign, so a good grasp of English is useful. On
						the other hand, you don’t need to know Czech at all.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h3 id={'our-job'}>Our job</h3>
					<p>
						At Gisat, we transform satellite data into useful information,
						helping our clients make better decisions. Most of our projects have
						a real-world impact - sometimes small, other times bigger. Although
						technically similar, the projects are also diverse, so you don’t get
						bored. In a year, we may work on checking the use of subsidies in
						agriculture or on infrastructure movement monitoring for the Czech
						state, water monitoring for the EU, examination of urban expansion
						for the World Bank or cooperation on a 3D map framework with NASA.
					</p>
					<p>
						We work on various projects utilizing satellite data and developing
						our own frameworks and custom-tailored solutions. We understand we
						work in a dynamic world, so we are open to building better
						structures and improving our delivery processes as well.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h3 id={'our-way-of-work'}>Our way of work</h3>
					<p>
						Your results are important to us, not when you’re sitting on a
						chair. We do not have limitations on working hours, but as a project
						manager, you need to be in close touch with management, developers,
						and different teammates across Gisat. We do prefer someone we can
						meet in the office on most days, but we often all work from home as
						well. Whether you want to work full-time or part-time, as an
						employee or a contractor is also up for negotiation. We don’t care
						what OS you’ll want on your work machine, and taking a vacation for
						a month is usually no problem.
					</p>
					<p>
						The whole company is less than thirty people, so you don’t need to
						be afraid of complicated processes and offices, several people
						large. We’re based in Letná, so most of Prague is close by, and we
						have a garden good for grill parties, a relaxation room, or a place
						to park your bike.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h3 id={'what-you-get'}>What do you get for it?</h3>
					<p>
						Pay CZK 70 000 - 90 0000 + bonuses, 5 weeks vacation time, benefits:
						contributions toward meals, pension, and public transport.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h3 id={'hiring-process'}>Hiring process</h3>
					<p>
						If you’re interested in the offer and reply, within a few days we’ll
						invite you for a personal interview with someone from management -
						we’ll agree if online or onsite. If we like each other, you’ll have
						the option (either right away, or later) to meet and talk with other
						members of the team and with the director.
					</p>
					<p>
						In any case, we will let you know whether or not we want you for our
						team as soon as we know. The whole process usually takes about two
						to four workweeks.
					</p>
					<p>
						If we agree on cooperation, you can start as needed, both
						immediately or after eventual release from your previous job.
					</p>
				</Content>
			</Block>
		</Layout>
	);
};

export default ProjectManager23;
